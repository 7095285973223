import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_5 = { class: "ms-3" }
const _hoisted_6 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
}
const _hoisted_7 = { class: "text-muted fs-7 mb-1" }
const _hoisted_8 = {
  class: "\n          p-5\n          rounded\n          bg-light-info\n          text-dark\n          fw-bold\n          mw-lg-400px\n          text-start\n        ",
  "data-kt-element": "message-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("img", {
            alt: "Pic",
            src: _ctx.image
          }, null, 8, ["src"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("a", _hoisted_6, _toDisplayString(_ctx.name), 1),
          _createVNode("span", _hoisted_7, _toDisplayString(_ctx.time), 1)
        ])
      ]),
      _createVNode("div", _hoisted_8, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}