import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "kt_scrolltop",
  ref: "kt_scrolltop",
  class: "scrolltop",
  "data-kt-scrolltop": "true"
}
const _hoisted_2 = { class: "svg-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", _hoisted_2, [
      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
    ])
  ], 512))
}